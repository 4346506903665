@import "src/variables.modules";

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  @media only screen and (min-width: 1025px) {
    flex-direction: row;
    justify-content: center;
  }

  h1 {
    font-size: 2.5rem;
    text-align: center;
    @media only screen and (min-width: 940px) {
      font-size: 3rem;
      text-align: left;
    }
  }

  h2 {
    font-size: 1.5rem;
    text-align: center;
    @media only screen and (min-width: 940px) {
      font-size: 2rem;
      text-align: left;
    }
  }

  ul {
    padding: .8rem;
  }

  li {
    font-size: 1.2rem;
    line-height: 1.8;
  }

  .firstName {
    background: -webkit-linear-gradient(135deg, $purple, $pink);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .hand {
    animation-name: wave;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
  }

  .emoji {
    margin-right: .5rem;
    font-size: 1.5rem;
    @media only screen and (min-width: 940px) {
     margin-right: 1rem;
    }
  }
}

.avatar {
  width: 35vh;
  // height: 60vh;
  border-radius: 50%;
  background: linear-gradient(to bottom right, $pink, $purple);
  padding: .75rem;
  margin-bottom: 1rem;

  @media only screen and (min-width: 940px) {
    width: 35vh;
    // height: 60vh;
    margin-right: 3rem;
    margin-bottom: 0;
  }
}

.socials {
  display: flex;
  justify-content: space-evenly;
  font-size: 2.5rem;
  padding: 1rem 0;

  a {
    color: $dark;
    transition: color 200ms ease;
    display: flex;
    align-items: center;
  }

  a:hover {
    color: $purple;
    transition: color 200ms ease;
    svg {
      fill: $purple;
      transition: fill 200ms ease;
    }
  }
}

a, a:visited {
  color: $dark;
}


@keyframes wave {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}