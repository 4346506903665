@import "src/variables.modules";

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}


.pink {
  color: $pink;
}

.green {
  color: $green;
}

.link {
  text-decoration: underline;
}

.emoji {
  margin-right: 1rem;
}

.main {
  padding-top: 60px;
}

.experience {
  columns: 1;
  margin-bottom: 1rem;
  
  li {
    margin: 0;
    line-height: 1.5;
  }
}

.technologies {
  columns: 2;
}

@media only screen and (max-width: 940px) {
  .technologies {
    columns: 1;
  }
}